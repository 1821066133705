import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, DatePicker } from "antd";
import { getTypeAndDateShopIdPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import moment from "moment";

function ReportExpense() {
  const navigate = useNavigate();
  const [shopId, setShopId] = useState("");
  const [reportList, setReportList] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          getAllItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportSource")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (shopId) => {
    let temp = {};
    let STDate = dateRange[0];
    let EDDate = dateRange[1];
    do {
      let key = EDDate.format("MM") + EDDate.format("YYYY");
      temp[key] = {};
      temp[key].month = EDDate.format("MMM");
      temp[key].mnth = EDDate.format("MM");
      temp[key].year = EDDate.format("YYYY");
      temp[key].total = 0;
      temp[key].Dealer = 0;
      temp[key].DirectVisit = 0;
      temp[key].Expo = 0;
      temp[key].Franchise = 0;
      temp[key].IndiaMart = 0;
      temp[key].Interior = 0;
      temp[key].JustDial = 0;
      temp[key].Reference = 0;
      temp[key].SocialMedia = 0;
      temp[key].StoreVisit = 0;
      temp[key].Website = 0;
      temp[key].Others = 0;
      if (EDDate.isBefore(STDate, "month") || EDDate.isSame(STDate, "month")) {
        break;
      }
      EDDate = EDDate.subtract(1, "months");
    } while (true);

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getTypeAndDateShopIdPagination(
        "Lead",
        shopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + 1;
          if (item.leadSource == "Dealer") value.Dealer = value.Dealer + 1;
          else if (item.leadSource == "Direct Visit")
            value.DirectVisit = value.DirectVisit + 1;
          else if (item.leadSource == "Expo") value.Expo = value.Expo + 1;
          else if (item.leadSource == "Franchise")
            value.Franchise = value.Franchise + 1;
          else if (item.leadSource == "India Mart")
            value.IndiaMart = value.IndiaMart + 1;
          else if (item.leadSource == "Interior")
            value.Interior = value.Interior + 1;
          else if (item.leadSource == "JustDial")
            value.JustDial = value.JustDial + 1;
          else if (item.leadSource == "Reference")
            value.Reference = value.Reference + 1;
          else if (item.leadSource == "Social Media")
            value.SocialMedia = value.SocialMedia + 1;
          else if (item.leadSource == "Store Visit")
            value.StoreVisit = value.StoreVisit + 1;
          else if (item.leadSource == "Website")
            value.Website = value.Website + 1;
          else if (item.leadSource == "Others") value.Others = value.Others + 1;
        });
    }

    // Convert to an array and sort it
    let sortedArray = Object.values(temp).sort((a, b) => {
      // Compare years first
      if (a.year !== b.year) {
        return a.year - b.year;
      }
      // If years are the same, compare months
      return a.mnth - b.mnth;
    });

    setReportList(sortedArray);

    // let temp = {};
    // let allItems = await getTypeAndDateShopIdPagination(
    //   "Quotation",
    //   shopId,
    //   dateRange[0].format("YYYY-MM-DD"),
    //   dateRange[1].format("YYYY-MM-DD")
    // );
    // if (allItems.length > 0)
    //   allItems.map((item) => {
    //     let leadSource = item.leadSource;
    //     if (temp[leadSource] == undefined) {
    //       temp[leadSource] = {};
    //       temp[leadSource].leadSource = leadSource;
    //       temp[leadSource].count = 1;
    //     } else temp[leadSource].count = temp[leadSource].count + 1;
    //   });
    // setReportList(Object.values(temp));
    setSpinnerState(false);
  };

  useEffect(() => {
    if (shopId != "") {
      setReportList([]);
      setSpinnerState(true);
      getAllItemsByType(shopId);
    }
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div style={{ marginBottom: 20, display: "flex" }}>
          <p style={{ marginRight: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
            picker="month"
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card style={{ width: "100%" }}>
              <h2>Leads count by source</h2>
              <div style={{ display: "flex" }}>
                <div style={{ width: 150 }}>
                  <p style={{ fontWeight: "bold" }}>Status</p>
                  <p style={{ fontWeight: "bold" }}>Total</p>
                  <p>Dealer</p>
                  <p>Direct Visit</p>
                  <p>Expo</p>
                  <p>India Mart</p>
                  <p>Interior</p>
                  <p>Just Dial</p>
                  <p>Reference</p>
                  <p>Social Media</p>
                  <p>Store Visit</p>
                  <p>Website</p>
                  <p>Others</p>
                </div>
                {reportList.map((item) => {
                  return (
                    <>
                      <div style={{ width: 150 }}>
                        <p style={{ fontWeight: "bold" }}>
                          {item.month}-{item.year}
                        </p>
                        <p style={{ fontWeight: "bold" }}>{item.total}</p>
                        <p>{item.Dealer}</p>
                        <p>{item.DirectVisit}</p>
                        <p>{item.Expo}</p>
                        <p>{item.IndiaMart}</p>
                        <p>{item.Interior}</p>
                        <p>{item.JustDial}</p>
                        <p>{item.Reference}</p>
                        <p>{item.SocialMedia}</p>
                        <p>{item.StoreVisit}</p>
                        <p>{item.Website}</p>
                        <p>{item.Others}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
