import React from "react";
import "../styles/headers.css";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Avatar, Dropdown, Menu, Row, Col, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Logo from "../images/Logo.jpg";
import { checkAccess } from "./AuthorizationService";

function Header(props) {
  const navigate = useNavigate();

  const avatarMenu = (
    <Menu>
      {checkAccess("ManageFormula") && (
        <Menu.Item
          onClick={() => {
            navigate("/ManageFormula");
          }}
          key="ManageFormula"
        >
          Manage Formulas
        </Menu.Item>
      )}
      {checkAccess("ManageShopDetails") && (
        <Menu.Item
          onClick={() => {
            navigate("/ManageShopDetails");
          }}
          key="ManageShopDetails"
        >
          Manage Shop Details
        </Menu.Item>
      )}
      {/* <Menu.Item
        onClick={() => {
          navigate("/ChangePassword");
        }}
        key="ChangePassword"
      >
        Change Password
      </Menu.Item> */}
      {/* <Menu.Item
        onClick={() => {
          navigate("/ForgotPassword");
        }}
        key="ForgotPassword"
      >
        Forgot Password
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          props.userLogout();
          signOut();
        }}
        key="logout"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const signOut = async () => {
    Auth.signOut();
    navigate("/");
  };

  return (
    <div className="header-wrapper">
      <Card className="header-card" hoverable>
        <Row>
          <Col span={2}>
            <img
              onClick={() => navigate("/HomePage")}
              src={Logo}
              alt="Logo"
              width={70}
              height={70}
              className="header-logo"
            />
          </Col>
          <Col span={4} offset={18} className="header-user">
            <span className="header-email">{props.common.email}</span>
            <Dropdown overlay={avatarMenu} placement="bottomRight">
              <Avatar
                style={{ backgroundColor: "black", marginBottom: 10 }}
                icon={<UserOutlined />}
                size={44}
              />
            </Dropdown>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    userLogout: (data) => dispatch({ type: "USER_LOGOUT", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
