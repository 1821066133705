import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";

// Create Document Component
export default function GenReportInvoice(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ alignItems: "center", margin: 5 }}>
            <Text>Invoice Report</Text>
          </View>
          <View style={{ borderWidth: 1, fontSize: 10, alignItems: "center" }}>
            <Text>ROYAL FABRICS</Text>
            <Text>312/44 THAMBU CHETTY STREET</Text>
            <Text>MANNADY,CHENNAI -600001</Text>
            <Text>8015611126 / 42043734</Text>
            <Text>Email : royalmosquitonets@gmail.com</Text>
            <Text>GSTIN/UIN: 33AMNPJ9237B1Z4</Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              fontSize: 10,
            }}
          >
            <View style={{ padding: 2 }}>
              <Text>Client Name</Text>
              <Text style={{ fontWeight: "bold" }}>{props.location}</Text>
            </View>
            <View style={{ width: "50%", padding: 2 }}>
              <Text>Dated:</Text>
              <Text style={{ fontWeight: "bold" }}>{props.dateRange}</Text>
            </View>
          </View>

          <View>
            <Text> </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.label}>Invoice No.</Text>
              <Text style={styles.label}>Invoice Date</Text>
              {props.from == "Outlet" && (
                <>
                  <Text style={styles.WONo}>WO No.</Text>
                  <Text style={styles.label}>Due Date</Text>
                  <Text style={styles.label}>Due In</Text>
                </>
              )}
              <Text style={styles.label}>Paid</Text>
              <Text style={styles.label}>Due Amount</Text>
              <Text style={styles.label}>Total</Text>
              <Text style={styles.label}>Invoice Status</Text>
              {/* {props.from != "production" && (
                <Text style={styles.amount}>Price/sqft</Text>
              )}
              <Text style={styles.amount}>Total</Text> */}
            </View>
            {props.allInvoice.map((item) => {
              return (
                <>
                  <View style={styles.tableData}>
                    <Text style={styles.label}> {item.randomId}</Text>
                    <Text style={styles.label}>
                      {" "}
                      {moment(item.date).format("DD MMM YY")}
                    </Text>
                    {props.from == "Outlet" && (
                      <>
                        <Text style={styles.WONo}> {item.PO_ID}</Text>
                        <Text style={styles.label}>
                          {" "}
                          {moment(item.paymentDueDate).format("DD MMM YY")}
                        </Text>
                        <Text style={styles.label}>
                          {" "}
                          {item.paymentStatus == "true"
                            ? "NA"
                            : moment(item.paymentDueDate).diff(
                                moment(new Date()).format("YYYY-MM-DD"),
                                "days"
                              )}
                        </Text>
                      </>
                    )}
                    <Text style={styles.label}>
                      {" "}
                      {parseFloat(item.paymentDone).toFixed(2)}
                    </Text>
                    <Text style={styles.label}>
                      {" "}
                      {parseFloat(item.paymentPending).toFixed(2)}
                    </Text>
                    <Text style={styles.label}>
                      {" "}
                      {item.totalAmount.toFixed(2)}
                    </Text>
                    <Text style={styles.label}>
                      {" "}
                      {item.paymentStatus == "false" ? "Due" : "Paid"}
                    </Text>
                  </View>
                </>
              );
            })}
          </View>
          <View>
            <Text> </Text>
          </View>
          <View>
            <Text style={{ fontSize: 10 }}>
              Total:{" "}
              {props.allInvoice.reduce(
                (sum, value) => sum + parseFloat(value.totalAmount),
                0
              )}
            </Text>
            <Text style={{ fontSize: 10 }}>
              Due Amount:{" "}
              {props.allInvoice.reduce(
                (sum, value) => sum + parseFloat(value.paymentPending),
                0
              )}
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 10 },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 10,
    marginRight: 10,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#D3D3D3",
    borderTopWidth: 1,
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  label: {
    padding: 2,
    width: "20%",
    textAlign: "center",
  },
  WONo: {
    padding: 2,
    width: "25%",
    textAlign: "center",
  },
});
