import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Typography } from "antd";
import "../styles/dashBoard.css";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByDatePagination,
  getItemsByTypeFunc,
  getItemsByOutletPagination,
  getItemsdatePagination,
  getAllItemsPagination,
} from "./functionCall";
import * as queries from "../graphql/queries";
const { Title } = Typography;

function Dashboard() {
  const [allOrder, setAllOrder] = useState("");
  const [lowInventory, setLowInventory] = useState([]);
  const [expList, setExpList] = useState([]);
  const [shopId, setShopId] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [listPI, setListPI] = useState({});
  const [listSI, setListSI] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "35%",
    },
    {
      title: "Location",
      dataIndex: "orderLocation",
      key: "orderLocation",
      ellipsis: true,
      width: "35%",
      render: (orderLocation) => orderLocation ?? "-",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "30%",
      align: "right",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
    },
  ];

  const columnsLowInventory = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "Stock",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      ellipsis: true,
      width: "30%",
      align: "right",
    },
  ];

  const columnsPI = [
    {
      title: "Vendor",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      width: "30%",
      align: "right",
      render: (total) => total.toFixed(2),
    },
  ];

  const columnsSI = [
    {
      title: "Outlet",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      width: "30%",
      align: "right",
      render: (total) => total.toFixed(2),
    },
    {
      title: "Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      ellipsis: true,
      width: "30%",
      align: "right",
      render: (paymentPending) => paymentPending.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          getSales(user.attributes["custom:outletShopId"]);
          getAllOrders(user.attributes["custom:shopId"]);
          getLowInventory(
            user.attributes["custom:shopId"],
            user.attributes["custom:outletShopId"]
          );
          getExpense(user.attributes["custom:outletShopId"]);
          getPI(user.attributes["custom:outletShopId"]);
          getSI(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("ReportOutletSales")) navigate("/homePage");
  }, []);

  const getAllOrders = async (shopId) => {
    let allItems = await getItemsByDatePagination(
      "Order",
      shopId,
      moment(new Date()).subtract(1, "M").format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD")
    );
    allItems = allItems.sort((a, b) => b.totalAmount - a.totalAmount);
    if (allItems.length > 5) allItems = allItems.slice(0, 5);
    setAllOrder(allItems);
  };

  const getLowInventory = async (shopId, outletShopId) => {
    let temp = await getItemsByTypeFunc("Component", shopId);
    let allItems = await getItemsByOutletPagination(
      "Inventory",
      "live",
      outletShopId
    );
    if (allItems.length > 0) {
      allItems.map((item) => {
        temp.map((value) => {
          if (value.id == item.itemId) {
            value.remainingQuantity = item.remainingQuantity;
            value.lowStockValue = item.lowStockValue;
          }
        });
      });
    }
    temp = temp.filter((item) => item.remainingQuantity < item.lowStockValue);
    setLowInventory(temp);
  };

  const getExpense = async (outletShopId) => {
    let temp = {};
    let categoryList = [];
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      categoryList = allTodos.data.getItemById.expCatList;
      setAllCategory(categoryList);
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    let newDate = moment();
    for (let i = 0; i < 2; i++) {
      let month = newDate.format("MMM");
      temp[month] = {};
      temp[month].month = month;
      temp[month].mnth = newDate.format("MM");
      temp[month].year = newDate.format("YYYY");
      temp[month].total = 0;
      newDate = newDate.subtract(1, "months");
    }

    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getItemsdatePagination(
        "Expense",
        outletShopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      let categoryListTemp = {};
      categoryList.map((item) => {
        categoryListTemp[item] = {};
        categoryListTemp[item].total = 0;
      });
      let catList = categoryListTemp;
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + parseFloat(item.totalAmount);
          let category = item.itemDescription;
          if (catList[category] != undefined) {
            catList[category].total =
              catList[category].total + parseFloat(item.totalAmount);
          }
        });
      value.categoryList = catList;
    }

    setExpList(temp);
  };

  const getPI = async (outletShopId) => {
    let allItems = await getAllItemsPagination("PurchaseInvoice", outletShopId);
    allItems = allItems.filter((item) => item.paymentStatus == "false");
    let PIList = {};
    allItems.map((item) => {
      let vendor = item.vendorName;
      if (PIList[vendor] == undefined) {
        PIList[vendor] = {};
        PIList[vendor].vendorName = item.vendorName;
        PIList[vendor].total = 0;
      }
      PIList[vendor].total = PIList[vendor].total + item.totalAmount;
    });
    setListPI(PIList);
  };

  const getSI = async (outletShopId) => {
    let allItems = await getAllItemsPagination("SalesInvoice", outletShopId);
    allItems = allItems.filter((item) => item.paymentStatus == "false");
    let SIList = {};
    allItems.map((item) => {
      let vendor = item.location;
      if (SIList[vendor] == undefined) {
        SIList[vendor] = {};
        SIList[vendor].location = item.location;
        SIList[vendor].total = 0;
        SIList[vendor].paymentPending = 0;
      }
      SIList[vendor].total = SIList[vendor].total + item.totalAmount;
      SIList[vendor].paymentPending =
        SIList[vendor].paymentPending + item.paymentPending;
    });
    let SIListTemp = Object.values(SIList);

    SIListTemp = SIListTemp.sort((a, b) => b.paymentPending - a.paymentPending);
    if (SIListTemp.length > 5) SIListTemp = SIListTemp.slice(0, 5);
    setListSI(SIListTemp);
  };

  const getSales = async (outletShopId) => {
    let data = [{ total: 0, paid: 0, due: 0, overDue: 0 }];
    let allItems = await getItemsdatePagination(
      "SalesInvoice",
      outletShopId,
      moment(new Date()).subtract(3, "M").format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD")
    );
    if (allItems.length > 0)
      allItems.map((item) => {
        data[0].total = data[0].total + parseFloat(item.totalAmount);
        if (item.paymentStatus == "false") {
          if (moment(item.paymentDueDate).isSame(moment(), "day"))
            data[0].due = data[0].due + item.paymentPending;
          if (moment(item.paymentDueDate).isBefore(moment(), "day"))
            data[0].overDue = data[0].overDue + item.paymentPending;
        } else data[0].paid = data[0].paid + item.paymentDone;
      });
    setSalesData(data);
  };

  return (
    <div className="Dashboard">
      <Row gutter={[16, 16]} justify="center">
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title>DASHBOARD</Title>
            <Title level={3}>Sales of last 90 days</Title>
            {salesData.map((item) => {
              return (
                <>
                  <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                  >
                    <div style={{ fontWeight: "bold" }}>
                      <p>Total:</p>
                      <p>Paid:</p>
                      <p>Due:</p>
                      <p>Over Due:</p>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <p>{item.total.toFixed(2)}</p>
                      <p>{item.paid.toFixed(2)}</p>
                      <p>{item.due.toFixed(2)}</p>
                      <p>{item.overDue.toFixed(2)}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title level={3}>Top 5 Overdue Outlets</Title>
            <Table
              dataSource={listSI}
              columns={columnsSI}
              pagination={false} // Hide default pagination
              footer={() => (
                <div style={{ textAlign: "right" }}>
                  <a onClick={() => navigate("/ListInvoice")}>Show More</a>
                </div>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title level={3}>Top 5 big orders in last 30 days</Title>
            <Table
              dataSource={allOrder}
              columns={columns}
              pagination={false} // Hide default pagination
              footer={() => (
                <div style={{ textAlign: "right" }}>
                  <a onClick={() => navigate("/ListOrder")}>Show More</a>
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>
      <div>
        <p />
      </div>
      <Row gutter={[16, 16]} justify="center">
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title level={3}>Low Stock Inventory</Title>
            <Table
              dataSource={lowInventory}
              columns={columnsLowInventory}
              pagination={false} // Hide default pagination
              footer={() => (
                <div style={{ textAlign: "right" }}>
                  <a onClick={() => navigate("/ListInventory/homepage")}>
                    Show More
                  </a>
                </div>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title level={3}>Expense Comparison</Title>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                border: "1px solid black",
                textAlign: "center",
                fontWeight: "bold",
                justifyContent: "space-around",
              }}
            >
              <p style={{ width: 120 }}>Expense</p>
              <p style={{ width: 120 }}>This Month</p>
              <p style={{ width: 120 }}>Last Month</p>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                border: "1px solid black",
                textAlign: "center",
                justifyContent: "space-around",
              }}
            >
              <p>
                {allCategory.map((item) => {
                  return (
                    <div>
                      <p style={{ width: 120 }}>{item}</p>
                    </div>
                  );
                })}
              </p>
              {Object.entries(expList).map(([key, value]) => {
                return (
                  <p>
                    {value.categoryList &&
                      Object.entries(value.categoryList).map(([key, value]) => {
                        return (
                          <div>
                            <p
                              style={{
                                width: 120,
                                textAlign: "right",
                                paddingRight: 20,
                              }}
                            >
                              {value.total.toFixed(2)}
                            </p>
                          </div>
                        );
                      })}
                  </p>
                );
              })}
            </div>
            <div style={{ textAlign: "right", paddingTop: 10 }}>
              <a onClick={() => navigate("/ReportExpense")}>Show More</a>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card style={{ width: "100%" }}>
            <Title level={3}>PI Outstanding</Title>
            <Table
              dataSource={Object.values(listPI)}
              columns={columnsPI}
              pagination={false} // Hide default pagination
              footer={() => (
                <div style={{ textAlign: "right" }}>
                  <a onClick={() => navigate("/ListPI")}>Show More</a>
                </div>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
