import React, { useState, useEffect } from "react";
import "../styles/addBanner.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import "../styles/addBill.css";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table, Button, Select } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getItemByVendorPagination } from "./functionCall";
const { Option } = Select;

function PurchasePayments(props) {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [allVendors, setAllVendor] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [paymentPending, setPaymentPending] = useState(0);
  const [status, setStatus] = useState("");

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: "10%",
      ellipsis: true,
      render: (paymentStatus) => (paymentStatus == "false" ? "Due" : "Done"),
    },
    {
      title: "Payment Pending",
      dataIndex: "paymentPending",
      key: "paymentPending",
      width: "10%",
      ellipsis: true,
      render: (paymentPending) => paymentPending.toFixed(2),
    },
    {
      title: "Payment Done",
      dataIndex: "paymentDone",
      key: "paymentDone",
      width: "10%",
      ellipsis: true,
      render: (paymentDone) => paymentDone.toFixed(2),
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "10%",
      ellipsis: true,
      render: (totalAmount) => totalAmount.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getallVendors(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("PurchasePayments")) navigate("/homePage");
  }, []);

  const getallVendors = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Vendor", shopId);
    setAllVendor(allItems);
  };

  useEffect(() => {
    const getInvoice = async () => {
      let tempPending = 0;
      let allPI = await getItemByVendorPagination(
        "PurchaseInvoice",
        "live",
        outletShopId,
        vendorId
      );
      allPI.map((item) => {
        console.log(item.paymentStatus);
        if (item.paymentStatus == "false") {
          tempPending = tempPending + item.paymentPending;
        }
      });
      if (status != "")
        allPI = allPI.filter((item) => item.paymentStatus == status);
      setAllInvoice(allPI);
      setPaymentPending(tempPending);
    };
    if (vendorId != "") getInvoice();
    else {
      setAllInvoice([]);
      setPaymentPending(0);
    }
  }, [vendorId, status]);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: 400 }}
            value={vendorName}
            showSearch
            onChange={(value) => {
              if (value == "") {
                setAllInvoice([]);
                setPaymentPending(0);
                setVendorId("");
                setVendorName("");
              } else {
                let temp = JSON.parse(value);
                setVendorId(temp.id);
                setVendorName(temp.shopName);
              }
            }}
          >
            <Option value="">Select a vendor</Option>
            {allVendors.map((value) => {
              return (
                <Option value={JSON.stringify(value)}>{value.shopName}</Option>
              );
            })}
          </Select>
          {vendorId != "" && (
            <>
              <Select
                style={{ backgroundColor: "white" }}
                value={status}
                onChange={(value) => {
                  setStatus(value);
                }}
              >
                <Option value="">Select a status</Option>
                <Option value="false">Not Paid</Option>
              </Select>
              <label style={{ fontWeight: "bold", fontSize: 16 }}>
                Payment Pending: {paymentPending}
              </label>
              {paymentPending > 0 && (
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`../DebitReceipt/${vendorId}/${paymentPending}`)
                  }
                >
                  Pay
                </Button>
              )}
              <Button
                type="primary"
                onClick={() =>
                  navigate(`../PaymentHistory/${vendorId}/purchase`)
                }
              >
                Payment History
              </Button>
            </>
          )}
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PurchasePayments);
