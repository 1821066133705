import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, Button, Select, DatePicker } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { getAllItemsPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import * as queries from "../graphql/queries";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const { Option } = Select;
function ListQuotation() {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [expCatList, setExpCatList] = useState([]);
  const [itemDescription, setItemDescription] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const headers = [
    { label: "Id", key: "randomId" },
    { label: "Added Date", key: "addedDate" },
    { label: "Item Name", key: "itemName" },
    { label: "Category", key: "itemDescription" },
    { label: "Expense Date", key: "date" },
    { label: "Total", key: "totalAmount" },
  ];

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "20%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/quotation`)}>
          {randomId}
        </a>
      ),
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "20%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Expense",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Category",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Expense Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      width: "20%",
      render: (date) => moment(date, "YYYY-MM-DD").format("DD MMM"),
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      width: "20%",
      render: (totalAmount) => parseFloat(totalAmount).toFixed(2),
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddExpense/${id}`)}>View</a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getOutletDetail(user.attributes["custom:outletShopId"]);
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Expense")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getAllItemsPagination("Expense", outletShopId);
    if (itemDescription != "")
      allItems = allItems.filter(
        (item) => item.itemDescription == itemDescription
      );
    // if (date != "")
    //   allItems = allItems.filter(
    //     (item) => item.date == date.format("YYYY-MM-DD")
    //   );

    if (dateRange[0] != "" && dateRange[1] != "") {
      let startDate = dateRange[0].format("YYYY-MM-DD");
      let endDate = dateRange[1].format("YYYY-MM-DD");

      allItems = allItems.filter(
        (item) =>
          moment(item.date, "YYYY-MM-DD").isSameOrAfter(startDate) &&
          moment(item.date, "YYYY-MM-DD").isSameOrBefore(endDate)
      );
    }
    setAllItems(allItems);
  };

  const getOutletDetail = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setExpCatList(res.expCatList ?? []);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  useEffect(() => {
    getAllItemsByType(outletShopId);
  }, [itemDescription, dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            name="Search"
            label="Search"
            variant="outlined"
            size="small"
            style={{ width: "40%", backgroundColor: "white", marginRight: 10 }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />

          <Select
            style={{
              width: "60%",
              backgroundColor: "white",
              flex: 1,
              marginRight: 10,
              height: 40,
            }}
            value={itemDescription}
            onChange={(value) => {
              setItemDescription(value);
            }}
          >
            <Option value="">Select a category</Option>
            {expCatList.map((value) => {
              return <Option value={value}>{value}</Option>;
            })}
          </Select>

          {/* <DatePicker
            placeholder="Date of expense"
            onChange={(date) => setDate(date)}
            value={date}
            style={{ width: "20%", height: 40 }}
          /> */}

          <RangePicker
            style={{ width: "20%", marginLeft: 30 }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />

          <Button type="primary" style={{ marginLeft: 10, marginRight: 10 }}>
            <Link to="../AddExpense/addNewRecord">
              <PlusOutlined /> New Expense
            </Link>
          </Button>
          {allItems.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={allItems}
                headers={headers}
                filename={
                  "Expense-" +
                  dateRange[0].format("MMM YY") +
                  "-" +
                  dateRange[1].format("MMM YY") +
                  ".csv"
                }
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
        </div>
        <p style={{ fontWeight: "bold" }}>
          {term == ""
            ? allItems.length
            : allItems.filter(
                (item) =>
                  item.randomId
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1 ||
                  item.itemName
                    .toString()
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              ).length}{" "}
          Expense(s) of value{" "}
          {term == ""
            ? allItems
                .reduce((sum, bill) => sum + bill.totalAmount, 0)
                .toFixed(2)
            : allItems
                .filter(
                  (item) =>
                    item.randomId
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1 ||
                    item.itemName
                      .toString()
                      .toString()
                      .toLowerCase()
                      .indexOf(term.toLowerCase()) > -1
                )
                .reduce((sum, bill) => sum + bill.totalAmount, 0)
                .toFixed(2)}
        </p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListQuotation);
