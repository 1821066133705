import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Select, Button } from "antd";
import { getInvoiceDateOVPagination, getItemsByTypeFunc } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { SpinnerCircular } from "spinners-react";
import ReportInvoice from "./genReportInvoice";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

function ReportExpense() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletId, setOutletId] = useState("");
  const [allOutlets, setAllOutlet] = useState([]);
  const [location, setLocation] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);
  const [from, setFrom] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const Columns = [
    {
      title: "Invoice No.",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Invoice Date",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
      render: (date) => moment(date).format("DD MMM YY"),
    },
    ...(from == "Outlet" || from == "Dealer"
      ? [
          {
            title: "WO No.",
            dataIndex: "PO_ID",
            key: "PO_ID",
            ellipsis: true,
          },
          {
            title: "Due Date",
            dataIndex: "paymentDueDate",
            key: "paymentDueDate",
            ellipsis: true,
            render: (paymentDueDate) =>
              moment(paymentDueDate).format("DD MMM YY"),
          },
          {
            title: "Due In",
            dataIndex: "paymentDueDate",
            key: "paymentDueDate",
            ellipsis: true,
            render: (paymentDueDate, record) =>
              record.paymentStatus == "true"
                ? "NA"
                : moment(paymentDueDate).diff(
                    moment(new Date()).format("YYYY-MM-DD"),
                    "days"
                  ),
          },
        ]
      : []),
    {
      title: "Paid",
      dataIndex: "paymentDone",
      key: "paymentDone",
      ellipsis: true,
      render: (paymentDone) => parseFloat(paymentDone).toFixed(2),
    },
    {
      title: "Due Amount",
      dataIndex: "paymentPending",
      key: "paymentPending",
      ellipsis: true,
      render: (paymentPending) => parseFloat(paymentPending).toFixed(2),
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      ellipsis: true,
      render: (totalAmount) => totalAmount.toFixed(2),
    },
    {
      title: "Invoice Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      ellipsis: true,
      render: (paymentStatus) => (paymentStatus == "false" ? "Due" : "Paid"),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportInvoice")) navigate("/homePage");
  }, []);

  const getAllOutlets = async (from, shopId) => {
    let allItems = await getItemsByTypeFunc(from, shopId);
    allItems.map((item) => {
      item.location = from == "Outlet" ? item.location : item.shopName;
    });
    setAllOutlet(allItems);
  };

  const getAllItemsByType = async (outletShopId) => {
    let allItems = await getInvoiceDateOVPagination(
      from == "Vendor" ? "PurchaseInvoice" : "SalesInvoice",
      outletShopId,
      outletId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    setAllInvoice(allItems);
    setSpinnerState(false);
  };

  useEffect(() => {
    const getInvoice = async () => {
      setAllInvoice([]);
      setSpinnerState(true);
      await getAllItemsByType(outletShopId);
    };
    if (outletShopId != "") getInvoice();
  }, [dateRange, location]);

  useEffect(() => {
    const getOutlet = async () => {
      setLocation("");
      await getAllOutlets(from, shopId);
    };
    if (shopId != "" && from != "") getOutlet();
  }, [from]);

  const downloadPDF = async () => {
    let date = `${dateRange[0].format("DD/MM/YY")}-${dateRange[1].format(
      "DD/MM/YY"
    )}`;
    await pdf(
      <ReportInvoice
        allInvoice={allInvoice}
        dateRange={date}
        location={location}
        from={from}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(
          blob,
          `Invoice Report-${location}-${dateRange[0].format(
            "DD/MM/YY"
          )}-${dateRange[1].format("DD/MM/YY")}.pdf`
        );
      });
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <Select
            style={{ backgroundColor: "white", width: "15%" }}
            value={from}
            onChange={(value) => {
              setFrom(value);
            }}
          >
            <Option value="">Select</Option>
            <Option value="Outlet">Outlet</Option>
            <Option value="Vendor">Vendor</Option>
            <Option value="Dealer">Dealer</Option>
          </Select>
          <Select
            style={{ backgroundColor: "white", width: "25%" }}
            value={location}
            onChange={(value) => {
              if (value == "") {
                setAllInvoice([]);
                setLocation("");
                setOutletId("");
              } else {
                let temp = JSON.parse(value);
                setLocation(temp.location);
                setOutletId(temp.id);
              }
            }}
          >
            <Option value="">Select {from}</Option>
            {allOutlets.map((value) => {
              return (
                <Option value={JSON.stringify(value)}>{value.location}</Option>
              );
            })}
          </Select>
          <p style={{ fontSize: 18, marginLeft: 20 }}>Select Date range</p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
          {allInvoice.length > 0 && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )}
        </div>
        {spinnerState && <SpinnerCircular />}
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={allInvoice}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
