import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  Table,
  Modal,
  message,
  Divider,
  Card,
  Select,
} from "antd";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TextField, InputAdornment } from "@mui/material";
import { api } from "../index";
import { v4 as uuidv4 } from "uuid";
import { getItemsByOutletPagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getAllItemsPagination10 } from "./functionCall";
import GeneratePI from "./generatePI";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
const { Option } = Select;

function AddProductGroups(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [PO_ID, setPO_ID] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [allVendors, setAllVendors] = useState({});
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesURI, setImagesURI] = useState([]);
  const [imagesBase64, setImagesBase64] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [outletDetails, setOutletDetails] = useState([]);
  const [shopDetails, setShopDetails] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [paymentDone, setPaymentDone] = useState(0);
  const [shippingAddress, setShippingAddress] = useState("");
  const [state, setState] = useState("");
  const [creditPeriod, setCreditPeriod] = useState("15");
  const [category, setCategory] = useState("");
  const [paymentDueDate, setPaymentDueDate] = useState(
    dayjs(moment(new Date()).add(15, "day"))
  );
  const paymentMargin = ["0", "7", "10", "15", "30", "45"];

  let test = useParams();

  const columns = [
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "HSN Code",
      dataIndex: "HSN",
      key: "HSN",
      ellipsis: true,
    },
    ...(test.test == "addNewPI"
      ? [
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            ellipsis: true,
            render: (quantity, record) => (
              <Input
                value={quantity}
                onChange={(e) => {
                  if (e.target.value == ".") {
                    addValue(record.key, "quantity", e.target.value);
                  } else if (isNaN(e.target.value))
                    message.error("Please enter valid value");
                  else addValue(record.key, "quantity", e.target.value);
                }}
                maxLength={6}
              />
            ),
          },
        ]
      : [
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            ellipsis: true,
          },
        ]),
    ...(test.test == "addNewPI"
      ? [
          {
            title: "Color",
            dataIndex: "color",
            key: "color",
            ellipsis: true,
            render: (color, record) => (
              <Select
                style={{ width: "90%" }}
                onChange={(value) => {
                  addValue(record.key, "color", value);
                }}
                value={color}
              >
                {props.common.colorArray
                  .concat(props.common.meshColorArray)
                  .map((value) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
              </Select>
            ),
          },
        ]
      : [
          {
            title: "Color",
            dataIndex: "color",
            key: "color",
            ellipsis: true,
          },
        ]),
    ...(test.test == "addNewPI"
      ? [
          {
            title: "Finished Product",
            dataIndex: "finishedProduct",
            key: "finishedProduct",
            ellipsis: true,
            render: (finishedProduct, record) => (
              <Select
                style={{ width: "90%" }}
                onChange={(value) => {
                  addValue(record.key, "finishedProduct", value);
                }}
                value={finishedProduct}
              >
                <Option key="Coated" value="Coated">
                  Coated
                </Option>
                <Option key="Raw Material" value="Raw Material">
                  Raw Material
                </Option>
                <Option key="Coating Done" value="Coating Done">
                  Coating Done
                </Option>
              </Select>
            ),
          },
        ]
      : [
          {
            title: "Finished Product",
            dataIndex: "finishedProduct",
            key: "finishedProduct",
            ellipsis: true,
          },
        ]),
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Rate",
            dataIndex: "itemPrice",
            key: "itemPrice",
            ellipsis: true,
            render: (itemPrice, record) => (
              <Input
                value={itemPrice}
                onChange={(e) => {
                  if (e.target.value == ".") {
                    addValue(record.key, "itemPrice", e.target.value);
                  } else if (isNaN(e.target.value))
                    message.error("Please enter valid value");
                  else addValue(record.key, "itemPrice", e.target.value);
                }}
                maxLength={6}
              />
            ),
          },
        ]
      : []),
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Total",
            dataIndex: "itemTotal",
            key: "itemTotal",
            ellipsis: true,
          },
        ]
      : []),
    ...(test.test == "addNewPI"
      ? [
          {
            title: "Delete",
            dataIndex: "id",
            key: "id",
            ellipsis: true,
            render: (id, record) => (
              <Button
                type="primary"
                onClick={() => removeComponent(record.key)}
                danger={true}
                style={{ width: "90%" }}
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Price",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{ width: "60%" }}
        >
          Add
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewPI") {
            getOutletDetails(user.attributes["custom:outletShopId"]);
            getShopDetails(user.attributes["custom:shopId"]);
            await getItemById();
          } else
            props.setBillItems({
              subTotal: 0,
              totalAmount: 0,
              extraCharges: 0,
              GST: 0,
              GSTAmount: 0,
              IGST: 0,
              IGSTAmount: 0,
              TDS: 0,
              TDSAmount: 0,
            });
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("PurchaseInvoice")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setItemDetails(res);
        setVendorId(res.vendorId);
        setVendorName(res.vendorName);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setAddress(res.address);
        setGSTNumber(res.GSTNumber);
        setPO_ID(res.PO_ID);
        setDate(
          res.date ? dayjs(moment(res.date).toDate()) : dayjs(new Date())
        );
        setComponentList(JSON.parse(res.componentList));
        setImages(res.itemImages ?? []);
        setImagesURI(res.itemImages ?? []);
        setPaymentDone(res.paymentDone);
        setShippingAddress(res.shippingAddress ?? "");
        setState(res.state ?? "");
        setPaymentDueDate(dayjs(moment(res.paymentDueDate).toDate()));
        setCategory(res.category ?? "");
        setCreditPeriod(res.creditPeriod ?? "");
        props.setBillItems({
          subTotal: res.subTotal,
          totalAmount: res.totalAmount,
          extraCharges: res.extraCharges,
          GST: res.GST,
          GSTAmount: res.GSTAmount,
          IGST: res.IGST,
          IGSTAmount: res.IGSTAmount,
          TDS: res.TDS,
          TDSAmount: res.TDSAmount,
        });
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getOutletDetails = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setOutletDetails(res);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getShopDetails = async (shopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setShopDetails(res);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      "Vendor",
      "live",
      outletShopId
    );

    let temp = {};
    allItems.map((item) => {
      if (temp[item.id] == undefined) {
        temp[item.id] = {};
        temp[item.id].id = item.id;
        temp[item.id].shopName = item.shopName;
        temp[item.id].phoneNumber = item.phoneNumber;
        temp[item.id].address = item.address;
        temp[item.id].GSTNumber = item.GSTNumber;
        temp[item.id].category = item.category ?? "";
      }
    });
    setAllVendors(temp);
  };

  const addItem = async (image) => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10(
      "PurchaseInvoice",
      outletShopId
    );
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "PI-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "PI-" + year + "-" + tempCounter;

    try {
      let PI = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          vendorId: vendorId,
          vendorName: vendorName,
          PO_ID: PO_ID,
          date: date.format("YYYY-MM-DD"),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "PurchaseInvoice",
          itemImages: image ?? images,
          paymentStatus: false,
          paymentDone: 0,
          paymentPending: props.PI.total,
          componentList: JSON.stringify(componentList),
          totalAmount: props.PI.total,
          subTotal: props.PI.subTotal,
          extraCharges: props.PI.extraCharges,
          GST: props.PI.GST,
          GSTAmount: props.PI.GSTAmount,
          IGST: props.PI.IGST,
          IGSTAmount: props.PI.IGSTAmount,
          TDS: props.PI.TDS,
          TDSAmount: props.PI.TDSAmount,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          GSTNumber: GSTNumber,
          shippingAddress: shippingAddress,
          state: state,
          paymentDueDate: paymentDueDate.format("YYYY-MM-DD"),
          category: category,
        },
      });

      try {
        let updatePO = await API.graphql({
          query: mutations.updatePIInventory,
          variables: {
            id: PI.data.addItem.id,
            updatedBy: updatedBy,
          },
        });
        message.error("Purchase Invoice Added");
        navigate(-1);
      } catch (error) {
        console.log(JSON.stringify(error));
        message.error(JSON.stringify(error));
      }

      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const editItem = async (image) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          vendorId: vendorId,
          vendorName: vendorName,
          PO_ID: PO_ID,
          date: date.format("YYYY-MM-DD"),
          updatedBy: updatedBy,
          itemImages: image ?? images,
          componentList: JSON.stringify(componentList),
          totalAmount: props.PI.total,
          subTotal: props.PI.subTotal,
          extraCharges: props.PI.extraCharges,
          GST: props.PI.GST,
          GSTAmount: props.PI.GSTAmount,
          IGST: props.PI.IGST,
          IGSTAmount: props.PI.IGSTAmount,
          TDS: props.PI.TDS,
          TDSAmount: props.PI.TDSAmount,
          phoneNumber: "+91" + phoneNumber,
          address: address,
          GSTNumber: GSTNumber,
          paymentStatus: paymentDone == props.PI.total ? "true" : "false",
          paymentDone: paymentDone,
          paymentPending: props.PI.total - paymentDone,
          paymentDueDate: paymentDueDate.format("YYYY-MM-DD"),
          category: category,
        },
      });
      message.error("Purchase Invoice updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (allVendors.length == 0) return message.error("Please add vendors");
    if (vendorName == "") return message.error("Select Vendor");
    if (phoneNumber === "") return message.error("Please enter vendor phone");
    if (address === "") return message.error("Please enter address");
    if (date === "") return message.error("Select invoice Date");
    if (paymentDueDate === "")
      return message.error("Please select payment due date");
    if (category === "") return message.error("Please enter category");
    if (Object.keys(componentList).length == 0)
      return message.error("Please add items to the purchase invoice");
    if (isNaN(props.PI.total)) return;
    if (props.PI.total == 0) return alert("Total cannot be 0");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
      if (
        value.itemPrice == "" ||
        isNaN(value.itemPrice) ||
        value.itemPrice == 0
      ) {
        flag = true;
        return message.error("Enter price for " + value.itemName);
      }
    });

    if (flag) return;

    setSpinnerState("true");
    var tempImage = undefined;
    if (
      (test.test === "addNewPI" && imagesURI.length > 0) ||
      (test.test != "addNewPI" && imagesURI != images)
    )
      try {
        const response = await fetch(api + "uploadImage", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            base64: imagesBase64,
            fileName: uuidv4() + ".jpeg",
          }),
        });
        const responseJson = await response.json();
        tempImage = responseJson.Location;
      } catch (error) {
        console.error(error);
      }

    if (test.test === "addNewPI") addItem(tempImage);
    else editItem(tempImage);
  };

  const onImageChange = (event) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    setImagesURI(URL.createObjectURL(files[0]));
    reader.onload = (e) => {
      setImagesBase64(e.target.result.replace(/^data:image\/\w+;base64,/, ""));
    };
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    let key = uuidv4();
    tempList[key] = {};
    tempList[key].id = item.id;
    tempList[key].key = key;
    tempList[key].itemName = item.itemName;
    tempList[key].HSN = item.HSN;
    tempList[key].itemPrice = item.masterPP;
    tempList[key].quantity = 0;
    tempList[key].finishedProduct = "Coated";
    tempList[key].color = "Other";
    tempList[key].itemTotal = 0;
    setComponentList(tempList);
    console.log(JSON.stringify(tempList));
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
    props.changeComponent({
      componentList: tempList,
    });
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    temp[key][value] = data;
    if (value == "quantity" || value == "itemPrice") {
      let total = 0;
      console.log(temp[key].quantity);
      if (
        temp[key].quantity &&
        temp[key].itemPrice &&
        temp[key].quantity != "" &&
        temp[key].itemPrice != ""
      )
        total = temp[key].quantity * parseFloat(temp[key].itemPrice);
      temp[key].itemTotal = parseFloat(total).toFixed(2);

      props.changeComponent({
        componentList: temp,
      });
    }

    setComponentList(temp);
  };

  const downloadPDF = async () => {
    await pdf(
      <GeneratePI
        item={itemDetails}
        vendorDetails={allVendors[vendorId]}
        outletDetails={outletDetails}
        shopDetails={shopDetails}
      />
    )
      .toBlob()
      .then(async (blob) => {
        saveAs(blob, `${itemDetails.randomId}.pdf`);
      });
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 40,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginLeft: -20,
            display: "flex",
            justifyContent: "space-between",
            margin: 10,
          }}
        >
          <h1 className="bill-heading">Purchase Invoice Details</h1>
          {test.test != "addNewPI" &&
            props.common.role != "Store" &&
            props.common.role != "ProdManager" && (
              <Button
                className="button"
                type="primary"
                onClick={() => downloadPDF()}
                style={{ width: 150 }}
              >
                Download Invoice
              </Button>
            )}
        </div>
        <Card style={{ width: "100%" }}>
          <Row gutter={[16, 16]}>
            {test.test === "addNewPI" ? (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                    marginTop: 7,
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Vendor
                  </label>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      flex: 1,
                    }}
                    value={vendorName}
                    onChange={(value) => {
                      let temp = JSON.parse(value);
                      setVendorId(temp.id);
                      setVendorName(temp.shopName);
                      setPhoneNumber(temp.phoneNumber.slice(3, 13));
                      setGSTNumber(temp.GSTNumber);
                      setAddress(temp.address);
                      setCategory(temp.category);
                    }}
                  >
                    {Object.entries(allVendors).map(([key, option]) => (
                      <Option key={option.value} value={JSON.stringify(option)}>
                        {option.shopName}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Col>
            ) : (
              <Col span={12}>
                <span>{vendorName}</span>
              </Col>
            )}
            <Col span={12}>
              <TextField
                label="Supplier Invoice Number"
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
                value={PO_ID}
                onChange={(e) => setPO_ID(e.target.value)}
                inputProps={{
                  maxLength: 100,
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
          {props.common.role != "Store" &&
            props.common.role != "ProdManager" && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      style={{ width: "100%", backgroundColor: "white" }}
                      margin="normal"
                      value={phoneNumber}
                      size="small"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      inputProps={{
                        maxLength: 10,
                      }}
                      autoComplete="off"
                    />
                    <TextField
                      label="GST Number"
                      variant="outlined"
                      style={{ width: "100%", backgroundColor: "white" }}
                      margin="normal"
                      value={GSTNumber}
                      size="small"
                      onChange={(e) => setGSTNumber(e.target.value)}
                      inputProps={{
                        maxLength: 10,
                      }}
                      autoComplete="off"
                    />
                  </>
                </Col>
                <Col span={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    style={{ width: "100%", backgroundColor: "white" }}
                    margin="normal"
                    value={address}
                    size="small"
                    onChange={(e) => setAddress(e.target.value)}
                    inputProps={{
                      maxLength: 500,
                    }}
                    multiline
                    rows={4}
                    autoComplete="off"
                  />
                </Col>
              </Row>
            )}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <>
                <DatePicker
                  className="input-box"
                  placeholder="Invoice Date"
                  onChange={(date) => setDate(date)}
                  value={date}
                  style={{ width: "100%", height: 40, paddingTop: 20 }}
                />
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={state}
                  onChange={(value) => setState(value)}
                >
                  <Option value="">Select State</Option>
                  {props.common.indianStatesAndUTs.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
                <div style={{ marginTop: 10 }}>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="myImage"
                    onChange={onImageChange}
                  />
                  <div style={{ marginLeft: "58%" }}>
                    <img src={imagesURI} width={180} />
                  </div>
                </div>
              </>
            </Col>
            <Col span={12}>
              <TextField
                label="Shipping Address"
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
                margin="normal"
                value={shippingAddress}
                size="small"
                onChange={(e) => setShippingAddress(e.target.value)}
                inputProps={{
                  maxLength: 100,
                }}
                autoComplete="off"
                multiline
                rows={4}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Credit Period: </label>
                <Select
                  style={{
                    width: "80%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={creditPeriod}
                  onChange={(value) => {
                    setCreditPeriod(value);
                    setPaymentDueDate(moment(date).add(value, "day"));
                  }}
                >
                  <Option value="">Days for payment</Option>
                  {paymentMargin.map((value) => {
                    return <Option value={value}>{value} Days</Option>;
                  })}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Payment Due Date: </label>
                <DatePicker
                  placeholder="Payment Due Date"
                  onChange={(date) => setPaymentDueDate(date)}
                  value={paymentDueDate}
                  style={{ width: "80%", height: 40, marginTop: 15 }}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Category: </label>
                <Select
                  style={{
                    width: "80%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={category}
                  onChange={(value) => setCategory(value)}
                >
                  <Option value="">Select Category</Option>
                  {props.common.purchaseCatArray.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row>
            {Object.entries(componentList).length > 0 && (
              <Col span={12}>
                <span>
                  {Object.entries(componentList).length} item(s) added
                </span>
              </Col>
            )}
          </Row>
        </Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              className="my-table"
            />
          </Col>
        </Row>
        {props.common.role != "Store" && props.common.role != "ProdManager" && (
          <Row gutter={[16, 16]}>
            <Col span={12}></Col>
            <Col span={12}>
              <Card style={{ width: "100%" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    marginLeft: 0,
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>
                        Sub Total:
                      </td>
                      <td style={{ padding: "8px", width: "55%" }}></td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        {props.common.symbol}
                        {parseFloat(props.PI.subTotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>
                        Transportation Charges:
                      </td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Input
                          style={{ width: "80%" }}
                          placeholder="Enter extra charges"
                          value={props.PI.extraCharges}
                          onChange={(e) => {
                            if (e.target.value < 0)
                              return message.error(
                                "Please enter positive value"
                              );
                            props.setExtraCharges({
                              extraCharges: e.target.value,
                              GST: props.PI.GST,
                              IGST: props.PI.IGST,
                              TDS: props.PI.TDS,
                            });
                          }}
                          maxLength={10}
                        />
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.PI.extraCharges).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>GST:</td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Select
                          style={{ width: "80%" }}
                          value={props.PI.GST}
                          onChange={(value) => {
                            props.setExtraCharges({
                              extraCharges: props.PI.extraCharges,
                              GST: value,
                              IGST: props.PI.IGST,
                              TDS: props.PI.TDS,
                            });
                          }}
                        >
                          <Option value="0">0%</Option>
                          <Option value="18">18%</Option>
                          <Option value="12">12%</Option>
                          <Option value="5">5%</Option>
                        </Select>
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.PI.GSTAmount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>IGST:</td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Select
                          style={{ width: "80%" }}
                          value={props.PI.IGST}
                          onChange={(value) => {
                            props.setExtraCharges({
                              extraCharges: props.PI.extraCharges,
                              GST: props.PI.GST,
                              IGST: value,
                              TDS: props.PI.TDS,
                            });
                          }}
                        >
                          <Option value="0">0%</Option>
                          <Option value="18">18%</Option>
                          <Option value="12">12%</Option>
                          <Option value="5">5%</Option>
                        </Select>
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.PI.IGSTAmount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>TDS:</td>
                      <td style={{ padding: "8px", width: "55%" }}>
                        <Select
                          style={{ width: "80%" }}
                          value={props.PI.TDS}
                          onChange={(value) => {
                            props.setExtraCharges({
                              extraCharges: props.PI.extraCharges,
                              GST: props.PI.GST,
                              IGST: props.PI.IGST,
                              TDS: value,
                            });
                          }}
                        >
                          <Option value="0">0%</Option>
                          <Option value="0.1">0.1%</Option>
                        </Select>
                      </td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        + {parseFloat(props.PI.TDSAmount).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: "8px", width: "30%" }}>Total:</td>
                      <td style={{ padding: "8px", width: "55%" }}></td>
                      <td
                        style={{
                          padding: "8px",
                          width: "80%",
                          textAlign: "right",
                        }}
                      >
                        {props.common.symbol}
                        {parseFloat(props.PI.total).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          {test.test === "addNewPI" && (
            <Col xs={24} md={12} lg={8}>
              <Button
                className="button"
                type="primary"
                size="large"
                disabled={spinnerState}
                onClick={() => setModalAddComponents(true)}
                style={{ marginTop: 20 }}
              >
                <PlusOutlined /> Add Items
              </Button>
            </Col>
          )}
          <Col xs={24} md={12} lg={8}>
            <Button
              className="button"
              type="primary"
              size="large"
              disabled={spinnerState}
              onClick={() => checkInput()}
              style={{ marginTop: 20 }}
            >
              {test.test === "addNewPI"
                ? "Add Purchase Invoice"
                : "Update Purchase Invoice"}
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        visible={modalAddComponents}
        width={"70%"}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={8} offset={20}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                  >
                    Add Item(s)
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    PI: state.PI,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBillItems: (data) => dispatch({ type: "SET_PI_ITEMS", payload: data }),
    changeComponent: (data) =>
      dispatch({ type: "CHANGE_PI_COM", payload: data }),
    setExtraCharges: (data) =>
      dispatch({ type: "SET_PI_EXTRA_CRG", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductGroups);
