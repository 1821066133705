import { store } from "../component/store";
const checkAccess = (menuItem) => {
  const state = store.getState();
  const commonState = state.common;
  const shopType = commonState.shopType;
  const role = commonState.role;
  const rootMenuItems = ["ManageOutlet", "ManageUser"];
  const masterMenuItems =
    role === "Owner"
      ? [
          "Dashboard",
          "Analytics",
          "Leads",
          "Quotation",
          "Order",
          "Production",
          "Installation",
          "Ticket",
          "Invoice",
          "Materials",
          "Product",
          "Inventory",
          "ManageOutlet",
          "ManageUser",
          "StockTransfer",
          "PurchaseRequest",
          "PurchaseInvoice",
          "PurchaseOrder",
          "ManageEmployee",
          "Attendance",
          "Payroll",
          "Expense",
          "Statement",
          "ManageFormula",
          "ManageShopDetails",
          "PurchasePayments",
          "SalesPayments",
          "Reports",
          "Loan",
          "ReportExpense",
          "ReportSales",
          "ReportPurchase",
          "ReportLeads",
          "ReportProd",
          "ReportProdGroup",
          "ReportOutletSales",
          "ReportFollowUp",
          "ReportSource",
          "ReportBank",
          "ReportCoating",
          "ReportInvoice",
          "ReportOutletInvc",
          "ReportProfitLoss",
        ]
      : role === "Telecallers"
      ? ["Leads", "Quotation", "Analytics"]
      : role === "Production"
      ? ["Production"]
      : role === "Order"
      ? ["Analytics", "Order"]
      : role === "Executive"
      ? ["Analytics", "Leads", "Quotation", "Order"]
      : role === "Store"
      ? ["Inventory", "PurchaseRequest", "StockTransfer", "PurchaseInvoice"]
      : role === "Admin"
      ? [
          "Analytics",
          "Invoice",
          "PurchaseInvoice",
          "PurchasePayments",
          "SalesPayments",
          "Expense",
          "Statement",
          "Reports",
          "ReportBank",
          "ReportSales",
          "ReportInvoice",
          "ReportOutletInvc",
          "ReportPurchase",
        ]
      : role === "Accountant"
      ? [
          "Analytics",
          "Invoice",
          "PurchaseInvoice",
          "PurchasePayments",
          "SalesPayments",
          "Expense",
          "Statement",
          "Inventory",
          "PurchaseRequest",
          "PurchaseInvoice",
          "PurchaseOrder",
          "StockTransfer",
          "Reports",
          "ReportBank",
          "ReportCoating",
          "ReportSales",
          "ReportPurchase",
          "ReportInvoice",
          "ReportOutletInvc",
        ]
      : role === "ProdManager"
      ? [
          "Analytics",
          "Inventory",
          "Production",
          "PurchaseRequest",
          "PurchaseInvoice",
          "StockTransfer",
        ]
      : role === "Analytics"
      ? ["Analytics"]
      : role === "TeamLeader"
      ? [
          "Dashboard",
          "Analytics",
          "Leads",
          "Quotation",
          "Order",
          "Production",
          "Installation",
          "Ticket",
          "Invoice",
          "Reports",
          "ReportLeads",
          "ReportFollowUp",
          "ReportSource",
        ]
      : [];

  const outletMenuItems =
    role === "Executive" || role === "Manager" || role === "Owner"
      ? [
          "Dashboard",
          "Analytics",
          "Leads",
          "Quotation",
          "Order",
          "Production",
          "Installation",
          "Ticket",
          "Product",
          "ManageShopDetails",
          "ReportType",
        ]
      : role === "Technician"
      ? ["Installation", "Ticket"]
      : [];

  let access = false;
  if (shopType === "root" && rootMenuItems.includes(menuItem)) access = true;
  else if (shopType === "master" && masterMenuItems.includes(menuItem))
    access = true;
  else if (shopType === "outlet" && outletMenuItems.includes(menuItem))
    access = true;
  return access;
};

export { checkAccess };
