import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addBanner.css";
import "../styles/test.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  message,
  Modal,
  Divider,
  Table,
  Card,
  Select,
} from "antd";
import "../styles/addBill.css";
import dayjs from "dayjs";
import { PlusOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { TextField, InputAdornment } from "@mui/material";
import { getAllItemsPagination10 } from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";

const { Option } = Select;
function AddLead(props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [randomId, setRandomId] = useState("");
  const [followUpNotes, setFollowUpNotes] = useState("");
  const [followUpLog, setFollowUpLog] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [assignToLocation, setToLocation] = useState("");
  const [assignToUser, setToUser] = useState("");
  const [userByOutlet, setUserByOutlet] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddGroup, setmodalAddGroup] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [term, setTerm] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [userId, setUserId] = useState("");
  const [oldStatus, setOldStatus] = useState("");
  const [oldLocation, setOldLocation] = useState("");

  const statusList = ["Follow Up", "Convert to Quote", "Cancelled"];

  let test = useParams();

  const columns = [
    {
      title: "Product Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },

    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => (
        <Button type="primary" danger onClick={() => removeGroup(id)}>
          {Object.keys(orderDetails).includes(id) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  const columnsListGroup = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addGroup(record)}
          style={{
            width: Object.keys(orderDetails).includes(id) ? "" : "93%",
            backgroundColor: Object.keys(orderDetails).includes(id)
              ? "#D22B2B"
              : "",
          }}
        >
          {Object.keys(orderDetails).includes(id) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    async function getCurrentUser() {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then(async (user) => {
            setAddedBy(user.attributes.email);
            setUpdatedBy(user.attributes.email);
            setShopId(user.attributes["custom:shopId"]);
            setOutletShopId(user.attributes["custom:outletShopId"]);
            setUserId(user.username);
            await getUsersByOutlet(user.attributes["custom:shopId"]);
            await getItemsByType(user.attributes["custom:shopId"]);
            if (test.test != "addNewLead") await getItemById();
          })
          .catch((err) => {
            console.log("currentAuthenticatedUser " + JSON.stringify(err));
          });
      } catch (e) {
        console.error(e);
      }
    }
    getCurrentUser();
    if (!checkAccess("Leads")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) {
        navigate(-1);
      } else {
        setName(res.name);
        setPhoneNumber(res.phoneNumber.slice(3, 13));
        setLocation(res.location);
        setAddress(res.address);
        setDate(res.addedDate);
        setAddedBy(res.addedBy);
        setNotes(res.notes);
        setStatus(res.status);
        setOldStatus(res.status);
        setOldLocation(
          res.assignToLocation
            ? res.assignToLocation != "NA"
              ? res.assignToLocation
              : ""
            : ""
        );
        setFollowUpLog(JSON.parse(res.followUpLog) ?? {});
        setFollowUpDate(dayjs(moment(res.followUpDate).toDate()));
        setRandomId(res.randomId);
        setToLocation(
          res.assignToLocation
            ? res.assignToLocation != "NA"
              ? res.assignToLocation
              : ""
            : ""
        );
        setToUser(
          res.assignToUser
            ? res.assignToUser != "NA"
              ? res.assignToUser
              : ""
            : ""
        );
        setOrderDetails(JSON.parse(res.orderDetails));
        setLeadSource(res.leadSource);
      }
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  const getUsersByOutlet = async (shopId) => {
    let temp = {};
    let allItems = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );

    allItems.map((item) => {
      if (item.location) {
        if (temp[item.location] == undefined) temp[item.location] = {};
        temp[item.location].outletShopId = item.outletShopId;
        if (temp[item.location].users == undefined)
          temp[item.location].users = {};
        temp[item.location].users[item.email] = {};
        temp[item.location].users[item.email].id = item.id;
      }
    });
    setUserByOutlet(temp);
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Group", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const editItem = async () => {
    let temp = { ...followUpLog };
    let date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    temp[date] = {};
    temp[date].followUpNotes = followUpNotes;
    temp[date].updatedBy = updatedBy;
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          location: location,
          address: address,
          notes: notes,
          updatedBy: updatedBy,
          status: status,
          followUpLog: JSON.stringify(temp),
          followedUpDate: moment(new Date()).format("YYYY-MM-DD"),
          followUpDate: followUpDate.format("YYYY-MM-DD"),
          assignToLocation:
            assignToLocation !== "" ? assignToLocation : props.common.location,
          assignToUser: assignToUser != "" ? assignToUser : addedBy,
          outletShopId:
            assignToLocation != ""
              ? userByOutlet[assignToLocation].outletShopId
              : outletShopId,
          assignToUserId:
            assignToLocation != ""
              ? assignToUser != ""
                ? userByOutlet[assignToLocation].users[assignToUser].id
                : "NA"
              : userId,
          orderDetails: JSON.stringify(orderDetails),
          leadSource: leadSource,
        },
      });
      if (status === "Convert to Quote") await addQuotation(temp);
      else alert("Lead updated");
      if (oldStatus != status) {
        navigate(-1);
        sendNotificationStatus();
      }
      if (oldLocation != assignToLocation) {
        navigate(-1);
        sendNotificationAssign(userByOutlet[assignToLocation].outletShopId);
      }
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addQuotation = async (followUpLog) => {
    console.log("addQuotation");
    let billPrefix = "";

    try {
      const allTodos1 = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res1 = allTodos1.data.getItemById;
      billPrefix = res1.billPrefix;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    const today = new Date();
    const year = today.getFullYear();
    let lastQtNo = undefined;

    let allItems = await getAllItemsPagination10("Quotation", outletShopId);

    if (allItems.length > 0) lastQtNo = allItems[0].randomId;
    else lastQtNo = "QT-" + billPrefix.toUpperCase() + "-" + year + "-0";

    let tempCounter =
      lastQtNo.slice(7, 11) == year ? parseInt(lastQtNo.slice(12)) + 1 : 1;
    let tempId =
      "QT-" + billPrefix.toUpperCase() + "-" + year + "-" + tempCounter;
    let paymentDetails = {
      totalAmount: 0,
      paymentPending: 0,
      paymentDueDate: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
      paymentDone: 0,
      details: {},
      status: "Unpaid",
    };

    try {
      let bill = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Quotation",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          location: location,
          email: "",
          address: address,
          shippingAddress: "",
          notes: notes,
          distance: 0,
          orderDetails: JSON.stringify({}),
          totalAmount: 0,
          time: moment(new Date()).format("HH:mm:ss"),
          subTotal: 0,
          GST: 0,
          GSTAmount: 0,
          discountValue: 0,
          discountType: "flat",
          discountAmount: 0,
          extraCharges: 0,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: tempId,
          followUpDate: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
          followUpStatus: "live",
          followUpLog: JSON.stringify(followUpLog),
          leadId: test.test,
          leadSource: leadSource,
          paymentDetails: JSON.stringify(paymentDetails),
          orderLocation: props.common.location,
        },
      });
      alert("Lead converted to Quotation");
      sendNotificationQuote();
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    try {
      let bill = await API.graphql({
        query: mutations.addItem,
        variables: {
          type: "Lead",
          addedBy: addedBy,
          name: name,
          phoneNumber: "+91" + phoneNumber,
          location: location,
          address: address,
          notes: notes,
          time: moment(new Date()).format("HH:mm:ss"),
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: Math.floor(Math.random() * 10000000000) + 1,
          followUpDate: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
          addedByOutletShopId: outletShopId,
          addedByLocation: props.common.location,
          assignToLocation: props.common.location,
          assignToUser: addedBy,
          orderDetails: JSON.stringify(orderDetails),
          leadSource: leadSource,
          assignToUserId: userId,
        },
      });
      alert("Lead Added");
      navigate(-1);
      sendNotification();
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (name === "") return message.error("Please enter customer name");
    if (phoneNumber === "") return message.error("Please enter customer phone");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(phoneNumber) === false)
      return message.error("Please enter valid phone number");
    if (leadSource === "") return message.error("Please select lead source");
    if (test.test != "addNewLead" && followUpNotes === "")
      return message.error("Please enter follow up notes");
    if (
      test.test != "addNewLead" &&
      status != "Cancelled" &&
      followUpDate.diff(new Date(), "days") < 0
    )
      return message.error("Please select follow up date greater than today");
    setSpinnerState(true);
    if (test.test === "addNewLead") addItem();
    else editItem();
  };

  const addGroup = (item) => {
    let tempList = { ...orderDetails };
    if (Object.keys(tempList).includes(item.id)) {
      delete tempList[item.id];
    } else {
      tempList[item.id] = {};
      tempList[item.id].id = item.id;
      tempList[item.id].itemName = item.itemName;
    }
    setOrderDetails(tempList);
  };

  const removeGroup = (key) => {
    let tempList = { ...orderDetails };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setOrderDetails(tempList);
  };

  const sendNotificationQuote = async () => {
    let allUsers = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );
    allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

    let tokenMobile = allUsers
      .filter((item) => item.token && item.token !== "")
      .map((item) => item.token);
    let tokenWeb = allUsers
      .filter((item) => item.tokenWeb && item.tokenWeb !== "")
      .map((item) => item.tokenWeb);

    let tokenTemp = tokenMobile.concat(tokenWeb);

    // let tokenTemp = allUsers
    //   .filter((item) => item.token && item.token !== "")
    //   .map((item) => item.token);

    if (tokenTemp.length > 0)
      try {
        await API.graphql({
          query: mutations.notification,
          variables: {
            message: "Lead from source " + leadSource + " converted to Quote",
            title: "Lead Converted to Quote",
            tokenArray: tokenTemp,
          },
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
  };

  const sendNotification = async () => {
    let allUsers = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );
    allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

    let tokenMobile = allUsers
      .filter((item) => item.token && item.token !== "")
      .map((item) => item.token);
    let tokenWeb = allUsers
      .filter((item) => item.tokenWeb && item.tokenWeb !== "")
      .map((item) => item.tokenWeb);
    let tokenTemp = tokenMobile.concat(tokenWeb);

    // let tokenTemp = allUsers
    //   .filter((item) => item.token && item.token !== "")
    //   .map((item) => item.token);

    if (tokenTemp.length > 0)
      try {
        await API.graphql({
          query: mutations.notification,
          variables: {
            message: "A new lead from " + leadSource + " for customer " + name,
            title: "New lead added",
            tokenArray: tokenTemp,
          },
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
  };

  const sendNotificationStatus = async () => {
    let allUsers = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );
    allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

    let tokenMobile = allUsers
      .filter((item) => item.token && item.token !== "")
      .map((item) => item.token);
    let tokenWeb = allUsers
      .filter((item) => item.tokenWeb && item.tokenWeb !== "")
      .map((item) => item.tokenWeb);
    let tokenTemp = tokenMobile.concat(tokenWeb);

    // let tokenTemp = allUsers
    //   .filter((item) => item.token && item.token !== "")
    //   .map((item) => item.token);

    if (tokenTemp.length > 0)
      try {
        await API.graphql({
          query: mutations.notification,
          variables: {
            message: "Status of lead changed to " + status,
            title: "Lead status updated",
            tokenArray: tokenTemp,
          },
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
  };

  const sendNotificationAssign = async (outletShopId) => {
    let allUsers = await getItemsByTypeFunc(
      props.common.shopName + " User",
      shopId
    );
    allUsers = allUsers.filter((item) => item.outletShopId == outletShopId);

    let tokenMobile = allUsers
      .filter((item) => item.token && item.token !== "")
      .map((item) => item.token);
    let tokenWeb = allUsers
      .filter((item) => item.tokenWeb && item.tokenWeb !== "")
      .map((item) => item.tokenWeb);
    let tokenTemp = tokenMobile.concat(tokenWeb);

    // let tokenTemp = allUsers
    //   .filter((item) => item.token && item.token !== "")
    //   .map((item) => item.token);

    if (tokenTemp.length > 0)
      try {
        await API.graphql({
          query: mutations.notification,
          variables: {
            message: "New lead assined from location " + oldLocation,
            title: "Lead assinged",
            tokenArray: tokenTemp,
          },
        });
      } catch (error) {
        console.log(JSON.stringify(error));
      }
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1200px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 className="bill-heading">Leads Details {randomId}</h3>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginRight: "8px", paddingRight: 25 }}
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {" "}
              <PlusOutlined />
              {test.test === "addNewLead" ? `Add Lead` : `Update Lead`}
            </Button>
            {status !== "Convert to Quote" && (
              <Button
                style={{ paddingRight: 25 }}
                disabled={spinnerState}
                onClick={() => setmodalAddGroup(true)}
              >
                {" "}
                <PlusOutlined />
                Add Product
              </Button>
            )}
          </Col>
          <div className="divider"></div>
        </Row>

        <Card
          style={{
            backgroundColor: "white", // Set the desired background color with reduced opacity
            backdropFilter: "blur(8px)",
            marginTop: 20,
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Customer Name:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Customer Number"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Phone Number:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength={10}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Location:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  maxLength={100}
                />
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Address:
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  maxLength={300}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Notes
                </label>
                <Input
                  style={{ flex: 1 }}
                  placeholder="Enter notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  maxLength={500}
                />
              </div>
            </Col>
            {test.test != "addNewLead" && (
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Status
                  </label>
                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={status}
                    onChange={(value) => {
                      setStatus(value);
                    }}
                  >
                    {statusList.map((key) => (
                      <Option value={key}>{key}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                }}
              >
                <label
                  style={{
                    width: "120px",
                    marginRight: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Select Source
                </label>
                <Select
                  style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                  value={leadSource}
                  onChange={(value) => {
                    setLeadSource(value);
                  }}
                >
                  <Option value="">Select Source</Option>
                  {props.common.leadSourceList.map((key) => (
                    <Option value={key}>{key}</Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          {test.test != "addNewLead" && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Assign to outlet
                  </label>
                  <Select
                    style={{ width: "100%", backgroundColor: "white", flex: 1 }}
                    value={assignToLocation}
                    onChange={(value) => {
                      setToLocation(value);
                      setToUser("");
                    }}
                  >
                    <Option value="">Select Outlet</Option>
                    {Object.keys(userByOutlet).map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              </Col>
              {assignToLocation != "" && (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0",
                    }}
                  >
                    <label
                      style={{
                        width: "120px",
                        marginRight: "8px",
                        fontWeight: "bold",
                      }}
                    >
                      Assign to person
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        flex: 1,
                      }}
                      value={assignToUser}
                      onChange={(value) => {
                        setToUser(value);
                      }}
                    >
                      <Option value="">Select User</Option>
                      {Object.keys(userByOutlet[assignToLocation].users).map(
                        (item) => (
                          <Option value={item}>{item}</Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
              )}
            </Row>
          )}
        </Card>

        <div className="divider2"></div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card style={{ width: "100%" }}>
              <div style={{ marginTop: 5 }}>
                <Table
                  dataSource={Object.values(orderDetails)}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              {test.test !== "addNewLead" && (
                <>
                  {test.test !== "addNewLead" && (
                    <h3>
                      Added By: {addedBy} on{" "}
                      {moment(date, "YYYY-MM-DD").format("DD MMM")}
                    </h3>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginBottom: 16 }}>
                      <span>Follow up notes: </span>
                      <Input
                        style={{ width: "200px", marginTop: 8 }}
                        maxLength={100}
                        value={followUpNotes}
                        onChange={(e) => setFollowUpNotes(e.target.value)}
                        placeholder="Follow-Up notes.."
                      />
                    </div>
                    <div style={{ marginBottom: 16, marginLeft: 20 }}>
                      <span>Next Follow up: </span>
                      <DatePicker
                        style={{ width: "200px", marginTop: 8 }}
                        placeholder="Next Follow Up Date"
                        onChange={(date) => setFollowUpDate(date)}
                        value={followUpDate}
                      />
                    </div>
                  </div>
                </>
              )}
              {Object.entries(followUpLog).length > 0 && (
                <div style={{ marginTop: -15 }}>
                  <h3>Follow Up Details</h3>
                  {Object.entries(followUpLog).map(([key, value]) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <p style={{ width: "30%" }}>{value.updatedBy}</p>
                        <p style={{ width: "10%" }}>
                          {moment(key).format("DD MMM YY")}
                        </p>
                        <p style={{ width: "60%" }}>{value.followUpNotes}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalAddGroup}
        width="60%"
        style={customStylesAddProducts}
        onCancel={() => setmodalAddGroup(false)}
        footer={null}
        title={<h2 style={{ fontSize: "20px" }}>Select Products</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        <div className="bill">
          <div className="bill-container">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16} lg={12} offset={2}>
                <TextField
                  label="Search by name"
                  variant="outlined"
                  style={{ width: "80%" }}
                  margin="normal"
                  value={term}
                  onChange={(e) => setTerm(e.target.value)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} md={16} lg={5} style={{ paddingTop: "2%" }}>
                <Button
                  type="primary"
                  block
                  onClick={() => setmodalAddGroup(false)}
                >
                  Ok
                </Button>
              </Col>

              <Col xs={24} md={16} lg={18} offset={2}>
                <Table
                  dataSource={
                    term === ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListGroup}
                  className="my-table"
                  pagination={{ pageSize: 100 }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddLead);
