import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table } from "antd";
import { getItemsdatePagination } from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ReportExpense() {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);

  const Columns = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      ellipsis: true,
      width: "15%",
      render: (total) => total.toFixed(2),
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      ellipsis: true,
      width: "15%",
      render: (paid) => paid.toFixed(2),
    },
    {
      title: "Due",
      dataIndex: "due",
      key: "due",
      ellipsis: true,
      width: "15%",
      render: (due) => due.toFixed(2),
    },
    {
      title: "Over Due",
      dataIndex: "overDue",
      key: "overDue",
      ellipsis: true,
      width: "15%",
      render: (overDue) => overDue.toFixed(2),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          await getAllItemsByType(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportSales")) navigate("/homePage");
  }, []);

  const getAllItemsByType = async (outletShopId) => {
    let temp = {};

    let newDate = moment();
    for (let i = 0; i < 3; i++) {
      let month = newDate.format("MMM");
      temp[month] = {};
      temp[month].month = month;
      temp[month].mnth = newDate.format("MM");
      temp[month].year = newDate.format("YYYY");
      temp[month].total = 0;
      temp[month].paid = 0;
      temp[month].due = 0;
      temp[month].overDue = 0;
      newDate = newDate.subtract(1, "months");
    }
    for (const value of Object.values(temp)) {
      let startDate = moment(`${value.year}-${value.mnth}-01`, "YYYY-MM-DD");
      let endDate = startDate.clone().endOf("month");
      let allItems = await getItemsdatePagination(
        "SalesInvoice",
        outletShopId,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD")
      );
      if (allItems.length > 0)
        allItems.map((item) => {
          value.total = value.total + parseFloat(item.totalAmount);
          if (item.paymentStatus == "false") {
            if (moment(item.paymentDueDate).isSame(moment(), "day"))
              value.due = value.due + item.paymentPending;
            if (moment(item.paymentDueDate).isBefore(moment(), "day"))
              value.overDue = value.overDue + item.paymentPending;
          } else value.paid = value.paid + item.paymentDone;
        });
    }
    setReportList(Object.values(temp));
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={reportList}
                  scroll={{ y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportExpense);
