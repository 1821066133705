import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import {
  getItemsByDatePagination,
  getItemsdatePagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import { DatePicker, Card } from "antd";
import { SpinnerCircular } from "spinners-react";
const { RangePicker } = DatePicker;

function ReportProfitLoss() {
  const navigate = useNavigate();
  const [outletShopId, setOutletShopId] = useState("");
  const [WOTotal, setWOTotal] = useState(0);
  const [purchaseAmt, setPurchaseAmt] = useState(0);
  const [expense, setExpense] = useState(0);
  const [spinnerState, setSpinnerState] = useState(false);
  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getData(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("ReportProfitLoss")) navigate("/homePage");
  }, []);

  const getData = async (outletShopId) => {
    setSpinnerState(true);
    console.log("Get Data");
    let allOrder = await getItemsByDatePagination(
      "Order",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let orderTotal = allOrder.reduce((sum, bill) => sum + bill.totalAmount, 0);
    setWOTotal(orderTotal);

    let allInvoice = await getItemsdatePagination(
      "PurchaseInvoice",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    console.log(allInvoice.length);
    let invTotal = allInvoice.reduce((sum, bill) => sum + bill.totalAmount, 0);

    setPurchaseAmt(invTotal);

    let allExpense = await getItemsdatePagination(
      "Expense",
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    console.log(allExpense.length);
    let expenseTotal = allExpense.reduce(
      (sum, bill) => sum + bill.totalAmount,
      0
    );

    setExpense(expenseTotal);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "") getData(outletShopId);
  }, [dateRange]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <p style={{ paddingRight: 20 }}>Select Date Range: </p>
          <RangePicker
            style={{ width: "20%" }}
            placeholder="Select Date Range"
            onChange={(date) => setDateRange(date)}
            value={dateRange}
          />
        </div>
        {spinnerState && <SpinnerCircular />}
        <Card style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: 200, fontWeight: "bold" }}>
              <p>Sales (WO total): </p>
              <p>Purshase Invoice Total: </p>
              <p>Expense Total: </p>
              <p>Profit: </p>
            </div>
            <div style={{ width: 200, textAlign: "right" }}>
              <p>{WOTotal.toFixed(2)}</p>
              <p>{purchaseAmt.toFixed(2)}</p>
              <p>{expense.toFixed(2)}</p>
              <p>{(WOTotal - purchaseAmt - expense).toFixed(2)}</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportProfitLoss);
