import React, { useState } from "react";
import "../styles/addBanner.css";
import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "antd";
import "../styles/addBill.css";
import { CloseOutlined } from "@ant-design/icons";

function PaymentModal(props) {
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [paymentNotes, setPaymentNotes] = useState("");

  const updatePaymentDetails = async () => {
    if (installmentAmount == "")
      return alert("Please enter installment amount");
    var numReg = /^[0-9]*$/;
    if (numReg.test(installmentAmount) === false)
      return alert("Please enter valid installment amount");
    if (paymentMode == "") return alert("Please select mode of payment");
    if (installmentAmount > props.paymentDetails.paymentPending)
      return alert(
        "Please enter installment amount less than or equal to pending payment"
      );

    let temp = props.paymentDetails;
    temp.paymentPending =
      parseFloat(temp.paymentPending) - parseFloat(installmentAmount);
    temp.paymentDone =
      parseFloat(temp.paymentDone) + parseFloat(installmentAmount);
    if (temp.paymentPending == 0) temp.status = "Paid";

    let id = uuidv4();
    temp.details[id] = {};
    temp.details[id].id = id;
    temp.details[id].installmentAmount = installmentAmount;
    temp.details[id].paymentMode = paymentMode;
    temp.details[id].paymentId = paymentId;
    temp.details[id].paymentNotes = paymentNotes;
    temp.details[id].date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    temp.details[id].updatedBy = props.updatedBy;

    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: props.id,
          paymentDetails: JSON.stringify(temp),
        },
      });
      props.setPaymentDetails(temp);
      setInstallmentAmount("");
      props.setPaymentMode("");
      setPaymentId("");
      setPaymentNotes("");
      setPaymentMode("");
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const markBadDebt = async () => {
    let temp = props.paymentDetails;
    temp.status = "Bad Debt";
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: props.id,
          paymentDetails: JSON.stringify(temp),
        },
      });
      props.setPaymentDetails(temp);
      props.setPaymentModal(false);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const waiveOff = async () => {
    let temp = props.paymentDetails;
    temp.status = "Waive-Off";
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: props.id,
          paymentDetails: JSON.stringify(temp),
        },
      });
      props.setPaymentDetails(temp);
      props.setPaymentModal(false);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <Modal
      visible={props.paymentModal}
      width={900}
      onCancel={() => props.setPaymentModal(false)}
      footer={null}
      title={<h2 style={{ fontSize: "20px" }}>PAYMENT DETAILS</h2>}
      closeIcon={
        <CloseOutlined
          style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
        />
      }
    >
      <div>
        <div className="fieldStyle" style={{ fontWeight: "bold" }}>
          <span>Total amount: {props.paymentDetails.totalAmount}</span>
          <span>Payment Pending: {props.paymentDetails.paymentPending}</span>
          <span>Payment Done: {props.paymentDetails.paymentDone}</span>
          <span>Status: {props.paymentDetails.status}</span>
          <span>
            Payment Due Date:{" "}
            {moment(props.paymentDetails.paymentDueDate, "YYYY-MM-DD").format(
              "DD MMM"
            )}
          </span>
        </div>
        {props.paymentDetails.paymentPending > 0 && (
          <>
            {(props.canEdit || props.from == "install") && (
              <div>
                <div className="addProductFormContainer">
                  <div className="fieldStyle">
                    <span className="fieldName">Installment Amount</span>
                    <input
                      className="addProdInputStyle"
                      value={installmentAmount}
                      onChange={(e) => setInstallmentAmount(e.target.value)}
                      maxLength={10}
                    />
                  </div>
                  <div className="fieldStyle">
                    <span className="fieldName">Payment Mode</span>
                    <select
                      className="addProdSelectStyle"
                      value={paymentMode}
                      onChange={(e) => setPaymentMode(e.target.value)}
                    >
                      <option value="">Select Payment Mode</option>;
                      <option value="Cash">Cash</option>;
                      <option value="Net-Banking">Net-Banking</option>;
                      <option value="Debit/Credit Card">
                        Debit/Credit Card
                      </option>
                      <option value="G-pay/UPI">G-pay/UPI</option>;
                      <option value="Cheque">Cheque</option>;
                      <option value="Others">Others</option>;
                    </select>
                  </div>
                  <div className="fieldStyle">
                    <span className="fieldName">Transaction Id</span>
                    <input
                      className="addProdInputStyle"
                      value={paymentId}
                      onChange={(e) => setPaymentId(e.target.value)}
                      maxLength={100}
                    />
                  </div>
                  <div className="fieldStyle">
                    <span className="fieldName">Payment Notes</span>
                    <input
                      className="addProdInputStyle"
                      value={paymentNotes}
                      onChange={(e) => setPaymentNotes(e.target.value)}
                      maxLength={500}
                    />
                  </div>
                </div>
                <div className="fieldStyle">
                  <Button
                    className="button"
                    type="primary"
                    style={{ margin: 10 }}
                    onClick={() => updatePaymentDetails()}
                  >
                    Add Payment Details
                  </Button>
                  <Button
                    className="button"
                    type="primary"
                    style={{ margin: 10 }}
                    onClick={() => waiveOff()}
                  >
                    Waive-Off
                  </Button>
                  <Button
                    className="button"
                    type="primary"
                    style={{ margin: 10 }}
                    onClick={() => markBadDebt()}
                  >
                    Mark Bad-Debt
                  </Button>
                </div>
              </div>
            )}
          </>
        )}

        {props.paymentDetails.details &&
          Object.entries(props.paymentDetails.details).length > 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid",
                }}
              >
                <p style={{ fontWeight: "bold", width: 200 }}>Amount</p>
                <p style={{ fontWeight: "bold", width: 200 }}>Mode</p>
                <p style={{ fontWeight: "bold", width: 200 }}>Transaction Id</p>
                <p style={{ fontWeight: "bold", width: 200 }}>Notes</p>
                <p style={{ fontWeight: "bold", width: 200 }}>Updated By</p>
              </div>
              {Object.entries(props.paymentDetails.details)
                .sort(
                  (a, b) =>
                    moment(b[1].date, "YYYY-MM-DD HH:mm:ss") -
                    moment(a[1].date, "YYYY-MM-DD HH:mm:ss")
                )
                .map(([key, value]) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid",
                        }}
                      >
                        <p style={{ width: 200 }}>
                          {parseFloat(value.installmentAmount).toFixed(2)}
                        </p>
                        <p style={{ width: 200 }}>{value.paymentMode}</p>
                        <p style={{ width: 200 }}>
                          {value.paymentId != "" ? value.paymentId : "-----"}
                        </p>
                        <p style={{ width: 200 }}>
                          {value.paymentNotes != ""
                            ? value.paymentNotes
                            : "-----"}
                        </p>
                        <p style={{ width: 200 }}>
                          {value.updatedBy} on{" "}
                          {moment(value.date, "YYYY-MM-DD HH:mm:ss").format(
                            "DD MMM hh:mm"
                          )}
                        </p>
                      </div>
                    </>
                  );
                })}
            </div>
          )}
        <div className="fieldStyle">
          <Button
            className="button"
            type="primary"
            onClick={() => props.setPaymentModal(false)}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
