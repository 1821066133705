import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Card, Table, DatePicker, Button } from "antd";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import {
  getItemsdatePagination,
  getItemsByDatePagination,
  getTypeAndDateShopIdPagination,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc } from "./functionCall";
import { SpinnerCircular } from "spinners-react";
const { RangePicker } = DatePicker;

function Analytics(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [type, setType] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [totalSales, setTotalSales] = useState(0);
  const [allOutlets, setAllOutlets] = useState({});
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [source, setSource] = useState("");
  const [shopId, setShopId] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);

  const [dateRange, setDateRange] = useState([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const typeArray =
    props.common.role === "Telecallers" && props.common.shopType == "master"
      ? ["Quotation", "Lead"]
      : props.common.role === "ProdManager" && props.common.shopType == "master"
      ? ["Production"]
      : props.common.role === "Accountant" && props.common.shopType == "master"
      ? ["PurchaseInvoice", "SalesInvoice"]
      : props.common.role === "TeamLeader" && props.common.shopType == "master"
      ? ["Order", "Quotation", "Lead"]
      : props.common.role === "Admin" && props.common.shopType == "master"
      ? ["SalesInvoice"]
      : props.common.shopType == "master"
      ? [
          "Order",
          "Quotation",
          "Lead",
          "Production",
          "PurchaseInvoice",
          "SalesInvoice",
        ]
      : ["Order", "Quotation", "Lead"];

  const statusArray =
    type == "Order"
      ? ["live", "In-Production", "Assign To RF"]
      : type == "Quotation"
      ? ["live", "Follow Up", "Convert to Order", "Rejected"]
      : type == "Lead"
      ? ["live", "Follow Up", "Convert to Quote", "Cancelled"]
      : type == "Production"
      ? [
          "live",
          "Cutting Complete",
          "Mesh Process",
          "Packed",
          "Dispatched",
          "Delivered",
          "Installed",
        ]
      : ["live"];

  const headers = [
    { label: "Id", key: "randomId" },
    type != "PurchaseInvoice" && type != "SalesInvoice"
      ? { label: "Name", key: "name" }
      : null,
    type != "Production" && type != "SalesInvoice"
      ? {
          label: type == "PurchaseInvoice" ? "Vendor PNo." : "Phone Number",
          key: "phoneNumber",
        }
      : null,
    type == "Production"
      ? { label: "Delivery Date", key: "estimatedDelivery" }
      : null,
    type == "Order" ? { label: "Order Location", key: "orderLocation" } : null,
    type === "Lead" || type === "Order" || type === "Quotation"
      ? { label: "Lead Source", key: "leadSource" }
      : null,
    type == "PurchaseInvoice"
      ? { label: "Vendor Name", key: "vendorName" }
      : null,
    { label: "Date", key: "addedDate" },
    type != "PurchaseInvoice" && type != "SalesInvoice"
      ? { label: "Status", key: "status" }
      : null,
    type == "SalesInvoice" ? { label: "Location", key: "location" } : null,
    type == "PurchaseInvoice" || type == "SalesInvoice"
      ? { label: "Invoice Date", key: "date" }
      : null,
    type == "SalesInvoice" ? { label: "WO Number", key: "PO_ID" } : null,
    type == "PurchaseInvoice" ? { label: "Supplier Id", key: "PO_ID" } : null,
    type == "PurchaseInvoice" || type == "SalesInvoice"
      ? { label: "Sub-Total", key: "subTotal" }
      : null,
    type == "PurchaseInvoice" || type == "SalesInvoice"
      ? { label: "GST Amount", key: "GSTAmount" }
      : null,
    type == "PurchaseInvoice" || type == "SalesInvoice"
      ? { label: "IGST Amount", key: "IGSTAmount" }
      : null,
    type != "Production" && type != "Lead"
      ? { label: "Amount", key: "totalAmount" }
      : null,
    type == "Lead"
      ? { label: "Assign to location", key: "assignToLocation" }
      : null,
  ];

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
      width: "10%",
      render: (randomId, record) => (
        <a onClick={() => navigate(`../ReadQuotation/${record.id}/order`)}>
          {randomId}
        </a>
      ),
    },
    ...(type == "SalesInvoice"
      ? [
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            ellipsis: true,
            width: "15%",
            render: (date) => moment(date, "YYYY-MM-DD").format("DD MMM"),
          },
        ]
      : []),
    ...(type != "PurchaseInvoice" && type != "SalesInvoice"
      ? [
          {
            title: "Customer Name",
            dataIndex: "name",
            key: "name",
            ellipsis: true,
            width: "20%",
            render: (name) => name ?? "NA",
          },
        ]
      : []),
    ...(type == "PurchaseInvoice"
      ? [
          {
            title: "Supplier Id",
            dataIndex: "PO_ID",
            key: "PO_ID",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
    ...(type == "PurchaseInvoice"
      ? [
          {
            title: "Vendor Name",
            dataIndex: "vendorName",
            key: "vendorName",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
    ...(type == "SalesInvoice"
      ? [
          {
            title: "WO Number",
            dataIndex: "PO_ID",
            key: "PO_ID",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
    ...(type == "SalesInvoice"
      ? [
          {
            title: "Location",
            dataIndex: "location",
            key: "location",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
    ...(type != "Production" && type != "SalesInvoice"
      ? [
          {
            title: type == "PurchaseInvoice" ? "Vendor PNo." : "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ellipsis: true,
            width: "15%",
            render: (phoneNumber) => phoneNumber ?? "NA",
          },
        ]
      : []),
    ...(type == "Order"
      ? [
          {
            title: "Order Location",
            dataIndex: "orderLocation",
            key: "orderLocation",
            ellipsis: true,
            width: "10%",
            render: (orderLocation) => orderLocation ?? "-",
          },
        ]
      : []),
    {
      title: type + " Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      width: "12%",
      render: (addedDate) => moment(addedDate, "YYYY-MM-DD").format("DD MMM"),
    },
    ...(type != "PurchaseInvoice" && type != "SalesInvoice"
      ? [
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ellipsis: true,
            width: "15%",
            render: (status) => (status == "live" ? "New" : status),
          },
        ]
      : []),
    ...(props.common.shopType == "master" && type === "Quotation"
      ? [
          {
            title: "Outlet",
            dataIndex: "orderLocation",
            key: "orderLocation",
            ellipsis: true,
            width: "10%",
          },
        ]
      : []),
    ...(type === "Lead" || type === "Order" || type === "Quotation"
      ? [
          {
            title: "Lead Source",
            dataIndex: "leadSource",
            key: "leadSource",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
    ...(type === "Production"
      ? [
          {
            title: "Delivery Date",
            dataIndex: "estimatedDelivery",
            key: "estimatedDelivery",
            ellipsis: true,
            width: "15%",
            render: (estimatedDelivery) =>
              moment(estimatedDelivery).format("DD MMM YY"),
          },
        ]
      : []),
    ...(type == "PurchaseInvoice" || type == "SalesInvoice"
      ? [
          {
            title: "Sub-Total",
            dataIndex: "subTotal",
            key: "subTotal",
            ellipsis: true,
            width: "10%",
            render: (subTotal) =>
              subTotal ? parseFloat(subTotal).toFixed(2) : "NA",
          },
        ]
      : []),
    ...(type == "PurchaseInvoice" || type == "SalesInvoice"
      ? [
          {
            title: "GST Amount",
            dataIndex: "GSTAmount",
            key: "GSTAmount",
            ellipsis: true,
            width: "15%",
            render: (GSTAmount) => GSTAmount.toFixed(2),
          },
        ]
      : []),
    ,
    ...(type == "PurchaseInvoice" || type == "SalesInvoice"
      ? [
          {
            title: "IGSTAmount Amount",
            dataIndex: "IGSTAmount",
            key: "IGSTAmount",
            ellipsis: true,
            width: "15%",
            render: (IGSTAmount) => IGSTAmount,
          },
        ]
      : []),
    ,
    ...(type != "Production" && type != "Lead"
      ? [
          {
            title: "Total",
            dataIndex: "totalAmount",
            key: "totalAmount",
            ellipsis: true,
            width: "10%",
            render: (totalAmount) =>
              totalAmount ? parseFloat(totalAmount).toFixed(2) : "NA",
          },
        ]
      : []),
    ...(type === "Lead"
      ? [
          {
            title: "Assign to Location",
            dataIndex: "assignToLocation",
            key: "assignToLocation",
            ellipsis: true,
            width: "15%",
          },
        ]
      : []),
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          setShopId(user.attributes["custom:shopId"]);
          setType(typeArray[0]);
          await getAllOutlets(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("Analytics")) navigate("/homePage");
  }, []);

  const getItemByTypeAndDate = async (outletShopId) => {
    let total = 0;
    let allItems = await getItemsByDatePagination(
      type,
      outletShopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    if (status != "")
      allItems = allItems.filter((item) => item.status == status);
    if (source != "")
      allItems = allItems.filter((item) => item.leadSource == source);

    allItems.map((item) => {
      total += item.totalAmount;
    });

    setAllItems(allItems);
    setTotalSales(total);
    setSpinnerState(false);
  };

  const getAllOutlets = async (shopId) => {
    let temp = {};

    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res1 = allTodos.data.getItemById;
      temp[res1.location] = {};
      temp[res1.location].location = res1.location;
      temp[res1.location].id = res1.id;
      setLocation(res1.location);
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    let allItems = await getItemsByTypeFunc("Outlet", shopId);
    allItems.map((item) => {
      temp[item.location] = {};
      temp[item.location].location = item.location;
      temp[item.location].id = item.id;
    });

    setAllOutlets(temp);
  };

  const getLeadsByShop = async (shopId) => {
    let allItems = await getTypeAndDateShopIdPagination(
      type,
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    if (status != "")
      allItems = allItems.filter((item) => item.status == status);
    if (source != "")
      allItems = allItems.filter((item) => item.leadSource == source);
    setAllItems(allItems);
    let total = 0;
    allItems.map((item) => {
      total += item.totalAmount;
    });
    setTotalSales(total);
    setSpinnerState(false);
  };
  const getInvoice = async (shopId) => {
    let allItems = await getItemsdatePagination(
      type,
      shopId,
      dateRange[0].format("YYYY-MM-DD"),
      dateRange[1].format("YYYY-MM-DD")
    );
    let total = 0;
    allItems.map((item) => {
      total += item.totalAmount;
    });
    setTotalSales(total);
    setAllItems(allItems);
    setSpinnerState(false);
  };

  useEffect(() => {
    if (outletShopId != "" && shopId != "" && type != "") {
      setAllItems([]);
      setTotalSales(0);
      setSpinnerState(true);
      if (outletShopId == shopId && (type == "Lead" || type == "Quotation"))
        getLeadsByShop(shopId);
      else if (type == "SalesInvoice" || type == "PurchaseInvoice")
        getInvoice(shopId);
      else getItemByTypeAndDate(outletShopId);
    }
  }, [type, outletShopId, dateRange, status, source]);

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
          backgroundColor: "#E2E2E2",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search.."
              variant="outlined"
              margin="normal"
              style={{
                width: "20%",
                backgroundColor: "#ffff",
                marginRight: 10,
              }}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            <TextField
              select
              margin="normal"
              label="Select Type"
              variant="outlined"
              style={{
                width: "15%",
                backgroundColor: "#ffff",
                marginRight: 10,
              }}
              value={type}
              onChange={(e) => {
                setType(e.target.value);
                setStatus("");
                setSource("");
              }}
              autoComplete="off"
            >
              {typeArray.map((item) => (
                <MenuItem key={item} value={item}>
                  {item.split(/(?=[A-Z])/).join(" ")}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              margin="normal"
              label="Select Status"
              variant="outlined"
              style={{
                width: "15%",
                backgroundColor: "#ffff",
                marginRight: 10,
              }}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              autoComplete="off"
            >
              <MenuItem key="" value="">
                Select Status
              </MenuItem>
              {statusArray.map((item) => (
                <MenuItem key={item} value={item}>
                  {item == "live" ? "New" : item}
                </MenuItem>
              ))}
            </TextField>
            {(type == "Lead" || type === "Order" || type === "Quotation") && (
              <TextField
                select
                margin="normal"
                label="Select Source"
                variant="outlined"
                style={{
                  width: "15%",
                  backgroundColor: "#ffff",
                  marginRight: 10,
                }}
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
                autoComplete="off"
              >
                <MenuItem key="" value="">
                  Select Source
                </MenuItem>
                {props.common.leadSourceList.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {props.common.shopType === "master" && (
              <TextField
                select
                label="Select Shop"
                variant="outlined"
                margin="normal"
                style={{
                  width: "15%",
                  backgroundColor: "#ffff",
                  marginRight: 10,
                }}
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                  setOutletShopId(allOutlets[e.target.value].id);
                }}
                autoComplete="off"
              >
                {Object.keys(allOutlets).map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <RangePicker
              style={{ width: "20%" }}
              placeholder="Select Date Range"
              onChange={(date) => setDateRange(date)}
              value={dateRange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>
              {term == ""
                ? allItems.length
                : allItems.filter(
                    (item) =>
                      item.randomId
                        .toString()
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) > -1 ||
                      item.name
                        .toString()
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) > -1 ||
                      item.phoneNumber
                        .toString()
                        .toLowerCase()
                        .indexOf(term.toLowerCase()) > -1
                  ).length}{" "}
              {type.split(/(?=[A-Z])/).join(" ")}(s){" "}
              {totalSales > 0 && term == "" && type != "Production"
                ? `of ${props.common.symbol} ${parseFloat(totalSales).toFixed(
                    2
                  )}`
                : ""}{" "}
              between {dateRange[0].format("DD MMM YY")}
              {" - "}
              {dateRange[1].format("DD MMM YY")}
            </h3>
            {allItems.length > 0 && (
              <Button type="primary">
                <CSVLink
                  data={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.name
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  headers={headers.filter((header) => header !== null)}
                  filename={
                    type.split(/(?=[A-Z])/).join(" ") +
                    "-" +
                    dateRange[0].format("DD MMM YY") +
                    "-" +
                    dateRange[1].format("DD MMM YY") +
                    ".csv"
                  }
                >
                  Export to CSV
                </CSVLink>
              </Button>
            )}
          </div>
          {spinnerState && <SpinnerCircular />}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card style={{ width: "100%" }}>
                <div>
                  <Table
                    columns={Columns}
                    dataSource={
                      term == ""
                        ? allItems
                        : allItems.filter(
                            (item) =>
                              item.randomId
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.name
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.phoneNumber
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1
                          )
                    }
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
